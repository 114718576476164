import React, { useState, useEffect, Component } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import profile from "../../../assests/img/profile.png";
import { Validators } from "../../../Utilities/Validator";
import { getJobSeeker } from "../../../Services/LeaderJobSeeker";
import { toast } from "react-toastify";
import { Roles } from "../../../Utilities/Helper";
import { getData } from "../../../Utilities/StorageHelper";

// export default class JobSeekersDetails extends React.Component {
//   state = {
//     email: "",
//     phone: "",
//     jobCompany: "",
//     note:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea  commodo consequat. Duis aute irure dolor in  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
//   };

//   constructor(props) {
//         super(props);
//         this.state = { isHidden: true }
//     }

//     toggleHidden () {
//       this.setState({ isHidden: !this.state.isHidden })
//     }

//   handleChange = (key) => (value) => {
//     this.setState({ [key]: value });
//   };

//   render() {
//     const { email, phone, jobCompany, note } = this.state;
//     const { isHidden } = this.state

const JobSeekersDetails = (props) => {
  const [jobSeeker, setJobSeeker] = useState({});
  const jobSeekerId = props.match.params.jobSeekerId;
  const [hideTemplate, setHideTemplate] = useState(true);
  useEffect(() => {
    async function getJobSeekerDetails() {
      getJobSeeker(jobSeekerId)
        .then((res) => {
          const result = res.response;
          setJobSeeker(result);
          setHideTemplate(false);
        })
        .catch((err) => {
          console.log("JobSeeker: ", err);
        });
    }
    getJobSeekerDetails();
  }, []);

  const handleChange = (value) => {};

  return (
    <div className="container p-0">
      <h1 className="page-title">Job Seekers Details</h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            {jobSeeker && (
              <div className="dashboard-box dashboard-col-bg float-left pb-5 w-100">
                {getData("role") === Roles.groupLeader && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/groupleader/jobseekers`}
                    className="page-sub-title-with-back"
                  >
                    <AiOutlineArrowLeft />
                    Back to job seekers
                  </Link>
                )}
                {getData("role") === Roles.admin && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/admin/manageusers`}
                    className="page-sub-title-with-back"
                  >
                    <AiOutlineArrowLeft />
                    Back to manage users
                  </Link>
                )}
                <div className="user-details d-flex align-items-center">
                  <div className="user-avatar">
                    {jobSeeker.profilePhoto !== null ? (
                      <img
                        src={`https://data.elqmq.upcloudobjects.com/ProfilePhotos/${jobSeeker.profilePhoto}`}
                      />
                    ) : (
                      <img src={profile} />
                    )}
                  </div>
                  <div className="user-name">
                    {jobSeeker.firstName + " " + jobSeeker.lastName}
                  </div>
                </div>
                <div className="users-wrap users-bg">
                  <div className="users-box left">
                    <Form>
                      <FormInput
                        value={jobSeeker.email}
                        type="text"
                        placeholder="Not available"
                        required="required"
                        label="Email"
                        ic
                        onChange={handleChange}
                      />
                      <FormInput
                        value={jobSeeker.phoneNumber}
                        type="text"
                        placeholder="Not available"
                        required="required"
                        label="Phone"
                        onChange={handleChange}
                      />
                      <FormInput
                        value={"N/A"}
                        type="text"
                        placeholder="Not available"
                        required="required"
                        label="Job Title & Company"
                        onChange={handleChange}
                      />
                    </Form>
                  </div>
                  {jobSeeker.jinTemplateId !== null && !hideTemplate && (
                    <div className="users-box right">
                      <h6 className="sub-title mb-4">Documents</h6>
                      <Link
                        to={`${process.env.PUBLIC_URL}/groupleader/resumedetails/${jobSeeker.jinTemplateId}`}
                      >
                        <Buttons
                          variant="light"
                          title="View Resume"
                          icon="icon icon-documents mr-2"
                          type="button"
                          size="xs"
                          color="white"
                          marginLeft="ml-0"
                        ></Buttons>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
// }
export default JobSeekersDetails;
