import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { toast } from "react-toastify";
import profile from "../../../assests/img/profile.png";
import { getEmployer } from "../../../Services/AdminManageUsersServices";

const EmployerDetails = (props) => {
  const [employer, setEmployer] = useState({});
  const employerId = props.match.params.employerId;

  useEffect(() => {
    async function getEmployerDetails() {
      getEmployer(employerId)
        .then((res) => {
          const result = res.response;
          setEmployer(result);
        })
        .catch((err) => {
          console.log("Support Details: ", err);
        });
    }
    getEmployerDetails();
  }, []);

  const handleChange = (value) => {};

  return (
    <div className="container p-0">
      <h1 className="page-title">Employer Details</h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            {employer && (
              <div className="dashboard-box dashboard-col-bg float-left pb-5 w-100">
                <Link
                  to={`${process.env.PUBLIC_URL}/admin/manageusers`}
                  className="page-sub-title-with-back"
                >
                  <AiOutlineArrowLeft />
                  Back to manage users
                </Link>

                <div className="user-details d-flex align-items-center">
                  <div className="user-avatar">
                    {employer.profilePhoto !== null ? (
                      <img
                        src={`https://data.elqmq.upcloudobjects.com/ProfilePhotos/${employer.profilePhoto}`}
                      />
                    ) : (
                      <img src={profile} />
                    )}
                  </div>
                  <div className="user-name">
                    {employer.firstName + " " + employer.lastName}
                  </div>
                </div>
                <div className="users-wrap users-bg">
                  <div className="users-box left">
                    <Form>
                      <FormInput
                        value={employer.email}
                        type="text"
                        placeholder="Not available"
                        required="required"
                        label="Email"
                        ic
                        onChange={handleChange}
                      />
                      <FormInput
                        value={employer.phoneNumber}
                        type="text"
                        placeholder="Not available"
                        required="required"
                        label="Phone"
                        onChange={handleChange}
                      />
                      <FormInput
                        value={employer.companyName}
                        type="text"
                        placeholder="Not available"
                        required="required"
                        label="Company Name"
                        onChange={handleChange}
                      />
                    </Form>
                  </div>
                  {/* <div className="users-box right">
                  <h6 className="sub-title mb-4">Documents</h6>
                  <Buttons
                      variant="light"
                      title="view resume"
                      icon="icon icon-documents mr-2"
                      type="button"
                      size="xs"
                      color="white"
                      marginLeft="ml-0"
                    ></Buttons>
                  <br/>
                  <br/>
                    <Buttons
                      variant="light"
                      title="view cover letter"
                      icon="icon icon-documents mr-2"
                      type="button"
                      size="xs"
                      color="white"
                      marginLeft="ml-0"
                    ></Buttons>
                  
                  </div> */}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EmployerDetails;
