import SpinnerLoader from "./SpinnerLoader";
import profile from "../assests/img/profile.png";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Roles } from "../Utilities/Helper";
import moment from "moment";
import { getData } from "../Utilities/StorageHelper";

export default function MessagesList({
  groupName,
  messages = [],
  showSpinner,
}) {
  const history = useHistory();

  const redirectTO = (index) => {
    if (Roles.jobSeeker == getData("role")) {
      history.push("/jobseeker/messages?" + index);
    }
    if (Roles.employer == getData("role")) {
      history.push("/employer/messages?" + index);
    }
    if (Roles.stm == getData("role")) {
      history.push("/support/messages?" + index);
    }
  };

  return (
    <ul className="job-list">
      {showSpinner && <SpinnerLoader />}
      {messages.length === 0 && !showSpinner && (
        <h5 style={{ textAlign: "center" }}>No Data Found</h5>
      )}
      {/* <p>Jobs</p> */}
      {messages.map((item, index) => (
        <li
          key={index}
          onClick={() => redirectTO(index)}
          style={{ cursor: "pointer" }}
        >
          <div className="avatar">
            {item.profilePhoto !== null ? (
              <img
                src={`https://data.elqmq.upcloudobjects.com/ProfilePhotos/${item.profilePhoto}`}
              />
            ) : (
              <img src={profile} />
            )}
          </div>
          <div className="message-details">
            <h4>{item.name}</h4>
            {item.text !== null && item.text !== "" && (
              <Fragment>
                {item.text.length > 130 && (
                  <p className="mb-0">{item.text.substr(0, 128) + "..."}</p>
                )}
                {item.text.length <= 130 && <p className="mb-0">{item.text}</p>}
              </Fragment>
            )}
          </div>
          <div className="job-post-date ml-auto">
            {moment(new Date(item.date.toString() + "Z").toString()).format(
              "MM.DD.Y"
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}
// export default MessagesList;
