import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import profile from "../assests/img/profile.png";
import {
  Button,
  Modal,
  Form,
  Toast,
  Alert,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import Buttons from "../Components/Shared/Button";
import SpinnerLoader from "./SpinnerLoader";
import moment from "moment";

export default function SupportTeamList({
  supportTeamMembers = [],
  onChangeStatus,
  showSpinner,
}) {
  const [showConfirmModal, setshowConfirmModal] = useState({
    title: "",
    show: false,
  });

  const [userData, setUserData] = useState({ id: null, status: null });

  const renderActiveTooltip = (props) => <Tooltip {...props}>Active</Tooltip>;

  const renderBlockTooltip = (props) => <Tooltip {...props}>Block</Tooltip>;

  const onOpenModalHandler = (jobSeekerId, status) => {
    setUserData({ id: jobSeekerId, status: status });
    if (status === true) {
      setshowConfirmModal({
        title: "Are you sure you want to block this support team member?",
        show: true,
      });
    } else {
      setshowConfirmModal({
        title: "Are you sure you want to activate this support team member?",
        show: true,
      });
    }
  };

  const onCloseModalHandler = () => {
    setshowConfirmModal({ title: "", show: false });
    setUserData({ id: null, status: null });
  };

  const onChangeStatusHandler = () => {
    onChangeStatus(userData);
    onCloseModalHandler();
  };

  return (
    <React.Fragment>
      <ul className="job-list support-team-list mobile-list">
        {/* <p>Jobs</p> */}
        <li className="headings">
          <div className="avatar border-0">&nbsp;</div>
          <div className="list-coulmn-right">
            <div className="name pr-3">Name </div>
            {/* <div className="email pr-3">Contact </div> */}
            <div className="email pr-3">Email </div>
            <div className="email pr-3">Phone </div>
            <div className="company company-pd">
              Network Responses/Requests{" "}
            </div>
            <div className="position">Position </div>
            <div className="company">Company </div>
            <div className="phone">City </div>
            <div className="subcat">Sub Category </div>
            <div className="statust">Status </div>
            <div className="text-right status">Action </div>
          </div>
        </li>
        {showSpinner && <SpinnerLoader />}
        {supportTeamMembers.length === 0 && !showSpinner && (
          <h5 style={{ textAlign: "center" }}>No Data Found</h5>
        )}
        {supportTeamMembers.map((item, index) => (
          <li key={item.supportTeamMemberId} className="align-items-center">
            <div className="avatar">
              {item.profilePhoto !== null ? (
                <img
                  alt="N/A"
                  src={`https://data.elqmq.upcloudobjects.com/ProfilePhotos/${item.profilePhoto}`}
                />
              ) : (
                <img alt="N/A" key={index} src={profile} />
              )}
              {/* src={item.avatar} */}
            </div>
            <div className="list-coulmn-right">
              <div className="name mobile-coulmn">
                <span className="mobile-column-name">Name</span>
                <span className="mobile-column-content">
                  <Link
                    to={`${process.env.PUBLIC_URL}/groupleader/supportteammembersdetails/${item.supportTeamMemberId}`}
                  >
                    {item.firstName + " " + item.lastName}
                    <span
                      className="job-post-date ml-auto hide-on-list pt-0"
                      style={{ color: "#7b7777" }}
                    >
                      <span className="fa fa-envelope"></span> {item.email}
                      <br />
                      {item.phone && (
                        <Fragment>
                          {" "}
                          <span
                            className="fa fa-phone"
                            style={{ transform: " scaleX(-1)" }}
                          ></span>{" "}
                          {item.phone}
                        </Fragment>
                      )}
                    </span>
                  </Link>
                </span>
              </div>
              <div className="job-post-date ml-auto hide-on-list pt-0 pr-3 mobile-coulmn">
                <span className="mobile-column-name">Date</span>
                <span className="mobile-column-content">
                  {moment(
                    new Date(item.createdDate.toString() + "Z").toString()
                  ).format("MM.DD.Y")}
                </span>
              </div>
              {/* <div className="email pr-3 break-word hide-email-on-mobile new-added-list">
                <span className="mobile-column-name">Email:</span>
                <span className="fa fa-envelope mr-1 hide-email-on-mobile"></span>
                <span className="mobile-column-content">{item.email}</span>
                <br></br>
                {item.phone && (
                  <span
                    className="fa fa-phone mr-1 d-inline-flex align-items-center hide-email-on-mobile"
                    style={{ transform: "scaleX(-1)" }}
                  ></span>
                )}
                {item.phone && (
                  <>
                    <span className="mobile-column-name">Phone:</span>
                    <span className="mobile-column-content">{item.phone}</span>
                  </>
                )}
              </div> */}
              <div className="email pr-3 break-word hide-email-on-mobile new-added-list">
                <span className="mobile-column-name">Email:</span>
                {/* <span className="fa fa-envelope mr-1 hide-email-on-mobile"></span> */}
                <span className="mobile-column-content">{item.email}</span>
              </div>
              <div className="email pr-3 break-word hide-email-on-mobile new-added-list">
                {/* {item.phone && (
                  <span
                    className="fa fa-phone mr-1 d-inline-flex align-items-center hide-email-on-mobile"
                    style={{ transform: "scaleX(-1)" }}
                  ></span>
                )} */}
                {item.phone && (
                  <>
                    <span className="mobile-column-name">Phone:</span>
                    <span className="mobile-column-content">{item.phone}</span>
                  </>
                )}
                {!item.phone && "-"}
              </div>
              <div className="company mobile-coulmn company-pd">
                <span className="mobile-column-name">
                  Network Responses/Requests
                </span>
                <span className="mobile-column-content">
                  {item.supportTeamMemberResponseNumber}/
                  {item.supportTeamMemberRequestNumber}
                </span>
                <span style={{ paddingLeft: "10px" }}>
                  {item.supportTeamMemberRequestNumber !== 0
                    ? Math.floor(
                        parseFloat(
                          item.supportTeamMemberResponseNumber /
                            item.supportTeamMemberRequestNumber
                        ) * 100
                      )
                    : 0}
                  %
                </span>
              </div>
              <div className="position mobile-coulmn">
                <span className="mobile-column-name">Postion</span>
                <span className="mobile-column-content">{item.position}</span>
              </div>
              <div className="company mobile-coulmn">
                <span className="mobile-column-name">Company Name</span>
                <span className="mobile-column-content">
                  {item.companyName}
                </span>
              </div>
              <div className="phone mobile-coulmn">
                <span className="mobile-column-name">City</span>
                <span className="mobile-column-content">{item.city}</span>
              </div>
              <div className="subcat mobile-coulmn">
                <span className="mobile-column-name">Sub Category</span>
                <span className="mobile-column-content">
                  {item.groupCategoryName}
                </span>
              </div>
              <div className="statust mobile-coulmn">
                <span className="mobile-column-name">Status</span>
                <span className="mobile-column-content">
                  {item.isActive ? "Active" : "InActive"}
                </span>
              </div>
              <div className="seeker-action-btns text-right status mobile-coulmn">
                {item.isActive === false && (
                  // {network.status}
                  <OverlayTrigger placement="top" overlay={renderActiveTooltip}>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() =>
                        onOpenModalHandler(
                          item.supportTeamMemberId,
                          item.isActive
                        )
                      }
                    >
                      <span className="icon-check mr-1"></span> Activate
                    </Button>
                  </OverlayTrigger>
                )}

                {item.isActive === true && (
                  <OverlayTrigger placement="top" overlay={renderBlockTooltip}>
                    <Button
                      variant="danger"
                      size="sm"
                      className="ml-0"
                      onClick={() =>
                        onOpenModalHandler(
                          item.supportTeamMemberId,
                          item.isActive
                        )
                      }
                    >
                      <span className="icon-block mr-1"></span>Deactivate
                    </Button>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>

      {/* Active confirm */}

      <Modal
        show={showConfirmModal.show}
        onHide={onCloseModalHandler}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={onCloseModalHandler}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">{showConfirmModal.title}</div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="Ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                onChangeStatusHandler();
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="Cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                onCloseModalHandler();
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>

      {/* block confirm */}

      {/* <Modal
        show={showblock}
        onHide={handleCloseBlock}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={handleCloseBlock}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            Are you sure you want to block this job seeker?
          </div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                setShowb(true);
                setShowblock(false);
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                setShowb(true);
                setShowblock(false);
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>
            */}
    </React.Fragment>
  );
}
